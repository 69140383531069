import React from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { Image, breakpoints, useResolution } from '@ecommerce/shared'
import Swiper from 'react-id-swiper'
import { SwiperParams } from '../../types/Constants'

const Skeleton = loadable(() => import('../Skeletons/CategorySkeleton'))

const Wrapper = styled.div<{ IconRight?: string; IconLeft?: string }>`
  overflow: scroll;
  width: 100%;

  .swiper-button {
    display: none;
    &-next,
    &-prev {
      background-size: 24px 24px;
      background-position: center;
      display: none;
      ::after {
        content: none;
      }
    }
  }

  .swiper-slide {
    width: 100px;
  }

  @media (${breakpoints.desktop.min}) {
    .swiper-slide {
      width: 200px;
    }
    .swiper-button {
      &-disabled {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
      }
      &-next {
        right: 8px;
        background-image: url(${(props) => (props.IconRight ? props.IconRight : '')});
      }
      &-prev {
        left: 8px;
        background-image: url(${(props) => (props.IconLeft ? props.IconLeft : '')});
      }
      &-next,
      &-prev {
        display: block;
        background-repeat: no-repeat;
        color: ${(props) => props.theme.colors.primary};
        height: 35px;
        width: 35px;
        opacity: 0.5;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.dark};
        user-select: none;
        outline: none;
        border: none;
        transition: all 0.5s;
        outline-style: none;
        -moz-outline-style: none;
        :hover {
          background-color: ${(props) => props.theme.colors.primary};
          opacity: 0.8;
        }
      }
    }
  }
`

const WrapperItem = styled.div<{ isClickable: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  margin-left: 10px;
  &:first-child {
    margin-left: 20px;
  }

  .category-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(3, 15, 28, 0.15);
    width: 80px;
    height: 80px;

    &-image {
      width: 101%;
      height: 101%;
    }
  }

  span {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.bodyText};
  }

  @media (${breakpoints.desktop.min}) {
    margin-left: 0;
    max-width: initial;
    &:first-child {
      margin-left: 0;
    }
    .category-box {
      width: 132px;
      height: 132px;
    }

    span {
      padding-top: 15px;
      font-size: 18px;
    }
  }
`

export interface Item {
  label: string
  imageUrl: string
  onOpen?: () => void
}

interface Props {
  items: Item[]
  isLoading?: boolean
}

const Categories = ({ items, isLoading = false }: Props) => {
  const params: SwiperParams = {
    rebuildOnUpdate: true,
    slidesPerGroup: useResolution().isDesktop ? 4 : 1,
    slidesPerView: 'auto',
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    freeMode: true,
    freeModeMomentumBounce: false,
    grabCursor: true,
    simulateTouch: true,
    speed: 1300,
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  if (isLoading)
    return (
      <Wrapper>
        <div>
          <Swiper {...params}>
            {Array(9)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} />
              ))}
          </Swiper>
        </div>
      </Wrapper>
    )

  return (
    <Wrapper
      IconRight="https://images.ctfassets.net/16npdkkoi5mj/31JdIGZYomjqVIatKBfQjb/2d46786c7dc837816bf76ec1a642eb39/chevron_right.png"
      IconLeft="https://images.ctfassets.net/16npdkkoi5mj/4PRKLyEQRdHKKUQmAqZQWZ/56eda383827ab0d6f4b656ae3a7e109a/chevron_left.png"
      data-test="categories-slider"
    >
      <Swiper {...params}>
        {items.map(({ label, onOpen, imageUrl }) => (
          <WrapperItem data-test="categories-slider-item" key={label} onClick={onOpen} isClickable={!!onOpen}>
            <div className="category-box">
              <Image className="category-box-image" src={imageUrl} alt={label} />
            </div>
            <span>{label}</span>
          </WrapperItem>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default Categories
