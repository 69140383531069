import { Slide } from '../types/PgPages'

export function normalizeContentfulSlider(contentfulSlider: Slide[]) {
  const imagesDesktop: any[] = []
  const imagesTablet: any[] = []
  const imagesMobile: any[] = []
  const slugs: string[] = []
  const names: string[] = []
  const titles: string[] = []
  const actionLabels: string[] = []
  const preTitles: string[] = []
  const descriptions: string[] = []
  const legals: string[] = []

  // eslint-disable-next-line no-restricted-syntax
  for (const slide of contentfulSlider) {
    imagesDesktop.push({
      background: slide?.desktopImage?.url || '',
      productImage: slide?.desktopProductImage ? slide?.desktopProductImage?.url || '' : undefined,
    })
    imagesTablet.push({
      background: slide?.tabletImage?.url || '',
      productImage: slide?.desktopProductImage ? slide?.desktopProductImage?.url || '' : undefined,
    })
    imagesMobile.push({
      background: slide?.mobileImage?.url || '',
      productImage: slide?.mobileProductImage ? slide?.mobileProductImage?.url || '' : undefined,
    })
    slugs.push(slide?.actionSlugLong || slide?.actionSlug || '')
    names.push(slide?.name || '')
    titles.push(slide?.title || '')
    preTitles.push(slide?.preTitle || '')
    descriptions.push(slide?.description || '')
    legals.push(slide?.legal || '')
    actionLabels.push(slide?.actionLabel || '')
  }

  return {
    imagesDesktop,
    imagesTablet,
    imagesMobile,
    slugs,
    names,
    titles,
    preTitles,
    descriptions,
    legals,
    actionLabels,
  }
}
