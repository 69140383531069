import { ClientSideBlock } from './contentfulTypes'

export interface HomeBlocksQueryResult {
  pgPageCollection: {
    items: {
      blocksCollection: {
        items: ClientSideBlock[]
      }
      template: {
        blocksCollection: {
          items: ClientSideBlock[]
        }
      }
    }[]
  }
}

export default `
  query BlocksQuery($currentCitySlug: String!) {
    pgPageCollection(where: {
      pageType: "home",
        OR: [
          {
            sublocations_contains_some: [$currentCitySlug]
          },
          {
            location: {
              slug: $currentCitySlug
            }
          }
        ],
    },
      limit: 1
  ) {
      items {
        blocksCollection {
          items {
            name
            blockType
            background {
              url
            }
            sys {
              id
            }
            itemsCollection(limit: 50) {
              items {
                ... on Product {
                  sku
                }
                ... on Bundle {
                  sku
                }
              }
            }
          }
        }
        template {
          ... on TmHome {
            blocksCollection {
              items {
                name
                blockType
                background {
                  url
                }
                sys {
                  id
                }
                itemsCollection(limit: 50) {
                  items {
                    ... on Product {
                      sku
                    }
                    ... on Bundle {
                      sku
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
