import React, { useState, useEffect } from 'react'
import { breakpoints, useResolution } from '@ecommerce/shared'
import styled from 'styled-components'
import useQuery from '../../hooks/useQuery'
import MomentsCardsList from '../../components/Moments/MomentsCardsList'

const query = `
  query MomentsListQuery($listId: String!) {
    orList(id: $listId) {
      name
      title
      actionSlug
      paddingTop
      paddingBottom
      itemsCollection {
        items {
          ... on MlMomentCard {
            name
            title
            subtitle
            slug
            image{
              url
              sys{
                id
              }
            }
            sys{
              id
            }
          }
        }
      }
    }
  }
`

type MomentsListQueryResult = {
  orList: {
    actionSlug?: string
    name: string
    title?: string
    paddingTop: boolean
    paddingBottom?: boolean
    itemsCollection: {
      items: {
        image: {
          url: string
          sys: {
            id: string
          }
        }
        name: string
        slug: string
        subtitle: string
        title: string
        sys: {
          id: string
        }
      }[]
    }
  }
}

const Title = styled.div`
  height: 70px;
  margin-top: 78px;
  padding: 25px 35px;

  span {
    font-size: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.bodyText};
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin-top: 25px;
    padding-left: 135px;
  }
`

export interface MomentsCardsListProps {
  listId: string
  citySlug: string
}

function MomentsCardsListWidget({ listId, citySlug }: MomentsCardsListProps) {
  const [momentsData, setMomentsData] = useState<MomentsListQueryResult['orList'] | null>(null)
  const { data, loading } = useQuery<MomentsListQueryResult>(query, { variables: { listId } })
  const { isMobile } = useResolution()
  useEffect(() => {
    if (data && !loading) setMomentsData(data.orList)
  }, [data, loading])
  return momentsData && !loading ? (
    <div>
      {momentsData?.title && (
        <Title>
          <span>{momentsData.title}</span>
        </Title>
      )}
      <MomentsCardsList
        isFixed={!isMobile && momentsData.itemsCollection.items.length < 3}
        items={momentsData.itemsCollection.items.filter(Boolean).map((item) => ({
          ...item,
          slug: item.slug.startsWith('/') ? `/${citySlug}${item.slug}` : `momento/${item.slug}`,
          id: item.sys.id,
          image: {
            file: { url: item.image.url },
            id: item.image.sys.id,
          },
        }))}
      />
    </div>
  ) : null
}

export default MomentsCardsListWidget
