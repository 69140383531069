import React, { useState, useEffect } from 'react'
import SwiperCore, { Navigation, Pagination, Autoplay, Controller } from 'swiper'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { breakpoints } from '@ecommerce/shared'
import { AtRibbonMessage } from '../../graphql/contentfulTypes'
import { Message } from './Message'

SwiperCore.use([Navigation, Pagination, Autoplay, Controller])

interface RibbonMessagesProps {
  messages: AtRibbonMessage[]
  citySlug?: string
}

const StyledSwiper = styled(Swiper)`
  .swiper {
    &-button {
      &-next,
      &-prev {
        display: flex;
        color: ${({ theme }) => theme.colors.black};
        height: 40px;
        width: 40px;
        transition: 200ms;
        &::after {
          font-weight: bolder;
          font-size: 12px;
        }
        right: 10px;
      }
      &-next {
        right: 5px;
      }
      &-prev {
        left: 5px;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .swiper {
      &-button {
        &-next,
        &-prev {
          height: 44px;
        }
        &-next {
          right: calc(12vw);
        }
        &-prev {
          left: calc(12vw);
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .swiper {
      &-button {
        &-next,
        &-prev {
          height: 44px;
        }
        &-next {
          right: calc(26vw);
        }
        &-prev {
          left: calc(26vw);
        }
      }
    }
  }
`
const dropdownAnimationMS = 8000

export const RibbonMessages = ({ messages, citySlug }: RibbonMessagesProps) => {
  const currentMessages = messages.map((message, index) => ({ ...message, index }))

  const [activeMessage, setActiveMessage] = useState(currentMessages[0])
  const [controller, setController] = useState<SwiperCore>()

  const transitionStart = () => {
    setActiveMessage({ ...activeMessage })
  }

  const transitionEnd = (swiper: SwiperCore) => setActiveMessage(currentMessages[swiper.realIndex])

  useEffect(() => {
    if (controller) {
      controller.off('slideChangeTransitionStart')
      controller.off('slideChangeTransitionEnd')
      controller.on('slideChangeTransitionStart', transitionStart)
      controller.on('slideChangeTransitionEnd', transitionEnd)
    }
  }, [controller, activeMessage?.index])

  return (
    <>
      <StyledSwiper
        slidesPerView={1}
        speed={dropdownAnimationMS}
        loop
        scrollbar={false}
        autoplay
        onSwiper={setController}
      >
        {currentMessages.map((currentMessage, i) => (
          <SwiperSlide key={`slide-${i}`}>
            <Message citySlug={citySlug} {...currentMessage} index={i} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </>
  )
}
