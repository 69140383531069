import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Image, toCssPrefix, breakpoints } from '@ecommerce/shared'
import { ContentfulImage } from '../../../graphql/contentfulTypes'
import useDrag from '../../../hooks/useDrag'

const { cssPrefix, toPrefix } = toCssPrefix('MomentCard__')

export interface MomentCardType {
  id: string
  title: string
  subtitle: string
  slug: string
  image: ContentfulImage
}

const Wrapper = styled.div`
  border-radius: 20px;
  width: 267px;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brandText.default};
  text-transform: uppercase;
  text-align: center;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};

  .${cssPrefix} {
    &content {
      position: absolute;
    }
    &image {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
    }

    &subtitle {
      letter-spacing: 12px;
      font-weight: bold;
      font-style: italic;
    }

    &title {
      border: 3px solid ${({ theme }) => theme.colors.brandText.default};
      padding: 10px 35px;
      font-size: 16px;
      letter-spacing: 5px;
      min-width: 166px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: 346px;
    height: 190px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 600px;
    height: 254px;
  }
`

const MomentCard = ({
  title,
  subtitle,
  image,
  slug,
  className = '',
  ...restProps
}: MomentCardType & React.HTMLAttributes<HTMLDivElement>) => {
  const { onMouseDown, onMouseUp, onTouchStart } = useDrag()
  const onClick = () => navigate(slug)

  return (
    <Wrapper
      {...restProps}
      role="button"
      onMouseDown={onMouseDown}
      onMouseUp={(e) => onMouseUp(e, onClick)}
      onTouchStart={onTouchStart}
      className={`${cssPrefix} ${className}`}
    >
      <Image className={toPrefix('image')} src={image.file.url} alt={title} />
      <div className={toPrefix('content')}>
        {subtitle && <p className={toPrefix('subtitle')}>{subtitle}</p>}
        {title && <h2 className={toPrefix('title')}>{title}</h2>}
      </div>
    </Wrapper>
  )
}

export default MomentCard
