import React, { useEffect, useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import { City, Country, SearchInput, sendDataToGTM, useAuth } from '@ecommerce/shared'
import styled from 'styled-components'
import { RibbonMessageSliderWidget } from '../../components/RibbonMessage'
import { Icon } from '../../components/Icon/Icon'
import { TabMenuMobile } from '../../components/TabMenu'
import { SearchBarContainer, SectionContainer } from './styled'
import homeBlocksQuery, { HomeBlocksQueryResult } from '../../graphql/homeBlocksQuery'
import { Block, BlockType } from '../../graphql/contentfulTypes'
import { useCartStockErrorHandler } from '../../utils/errors'
import { getTabs } from '../../mocks'
import { CategoriesSkeleton } from './Categories'
import useQuery from '../../hooks/useQuery'
import { BannerSliderWidget } from '../../components/BannerSlider'
import SliderComponent from '../../components/Slider'
import sliderHomeQuery, { SliderHomeQueryResult } from '../../graphql/sliderHomeQuery'
import { normalizeContentfulSlider } from '../../utils/normalize'
import FavoriteProducts from './FavoriteProducts'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import { extractBlocksSkus } from '../utils'
import OrderTrackingCTA from './OrderTrackingCTA'
import secrets from '../../config/secrets'
import Promotions from './Promotions'
import MomentsCardsListWidget from './MomentsCardsList'
import SkuList from './SkuList'

const { COUNTRY } = secrets

const Categories = loadable(() => import('./Categories'), {
  fallback: <CategoriesSkeleton />,
})
const PromosGridWidget = loadable(() => import('../../components/Moments/PromosGrid/PromosGridWidget'))
const HeadingWidget = loadable(() => import('../../components/Heading/HeadingWidget'))
const CountdownWidget = loadable(() => import('../../components/Countdown/CountdownWidget'))

type GQLNodeBlocks = Omit<Block, 'title' | 'items' | 'sys'>[]

const HomeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bodyBackground.default};
`

interface Props {
  currentCity: City
  pageBlocks: GQLNodeBlocks
  tabs: ReturnType<typeof getTabs>
}

const Home = ({ currentCity, tabs }: Props) => {
  const [blocks, setBlocks] = useState<GQLNodeBlocks>([])

  const {
    state: { isAuth },
  } = useAuth()
  const { data: blocksData } = useQuery<HomeBlocksQueryResult>(homeBlocksQuery, {
    variables: { currentCitySlug: currentCity.slug },
    deps: [isAuth],
  })

  const blocksCollection = blocksData?.pgPageCollection?.items?.[0]?.blocksCollection?.items?.length
    ? blocksData?.pgPageCollection?.items?.[0]?.blocksCollection?.items
    : blocksData?.pgPageCollection?.items?.[0]?.template?.blocksCollection?.items

  const skus = useMemo(() => extractBlocksSkus(blocksCollection ?? []), [blocksCollection])

  const { products, loadingProducts } = useAlgoliaFetch({
    city: currentCity,
    skus,
  })

  function formatBlocksClientSide() {
    if (blocksData) {
      const blockItems = blocksData.pgPageCollection.items[0].blocksCollection.items.length
        ? blocksData.pgPageCollection.items[0].blocksCollection.items
        : blocksData.pgPageCollection.items[0].template.blocksCollection.items

      const newBlocks = blockItems.map((item) => ({
        blockType: item.blockType,
        contentful_id: item.sys.id,
        name: item.name,
        background: item.background,
      }))

      setBlocks(newBlocks)
    }
  }

  useEffect(formatBlocksClientSide, [blocksData])
  useEffect(() => {
    sendDataToGTM({ event: 'home' })
  }, [])

  const sliderQueryResult = useQuery<SliderHomeQueryResult>(sliderHomeQuery, {
    variables: { currentCitySlug: currentCity.slug },
  })
  const slider = sliderQueryResult.data
    ? sliderQueryResult.data.pgPageCollection.items[0].template.slider.slidesCollection.items
    : []
  const sliderItems = normalizeContentfulSlider(slider)
  const backupSlider = !blocks.some((block) => block.blockType === BlockType.BANNER_SLIDER) ? (
    <>
      <SliderComponent
        items={sliderItems}
        onSlideClick={(slug) => (slug ? navigate(`/${currentCity.slug}/${slug}`) : null)}
        isLoading={sliderQueryResult.loading || sliderQueryResult.error !== undefined}
      />
      <SearchBarContainer>
        <SearchInput
          className="search-bar"
          type="text"
          placeholder="Busca algún producto"
          data-test="search-input"
          onClick={() => navigate(`/${currentCity.slug}/search`)}
          CustomSearchIcon={() => (
            <Icon iconId="search" onClick={() => navigate(`/${currentCity.slug}/search`)} cursor="pointer" />
          )}
        />
      </SearchBarContainer>
      <TabMenuMobile tabs={tabs} currentCity={currentCity} />
    </>
  ) : null

  const stockErrorHandler = useCartStockErrorHandler()

  return (
    <HomeWrapper>
      {backupSlider}
      {blocks?.map((block, index) => {
        const { contentful_id, name } = block
        const hasSearchboxPadding = index > 0 && blocks[index - 1].blockType === BlockType.BANNER_SLIDER
        const background = block.background?.url

        switch (block.blockType) {
          case BlockType.PROMO_LARGE:
          case BlockType.PROMO_SMALL: {
            return (
              <SectionContainer
                hasSearchboxPadding={hasSearchboxPadding}
                key={name}
                className="promos-container"
                background={background}
                hasAltBackground
              >
                <Promotions showRibbon={false} listId={contentful_id} citySlug={currentCity.slug} />
              </SectionContainer>
            )
          }

          case BlockType.HEADING: {
            return <HeadingWidget listId={contentful_id} />
          }

          case BlockType.COUNTDOWN: {
            return <CountdownWidget listId={contentful_id} />
          }

          case BlockType.CATEGORY: {
            return (
              <SectionContainer hasSearchboxPadding={hasSearchboxPadding} key={name}>
                <Categories listId={contentful_id} />
              </SectionContainer>
            )
          }

          case BlockType.PRODUCT: {
            return (
              <SkuList
                loadingProducts={loadingProducts}
                pageProducts={products}
                key={name}
                slugLocation={currentCity.slug}
                listId={contentful_id}
                stockErrorHandler={stockErrorHandler}
                itsHome
              />
            )
          }

          case BlockType.BANNER_SLIDER: {
            return (
              <React.Fragment key={name}>
                <BannerSliderWidget listId={contentful_id} citySlug={currentCity.slug} />
              </React.Fragment>
            )
          }

          case BlockType.PROMO_XL_GRID: {
            return <PromosGridWidget citySlug={currentCity.slug} listId={contentful_id} />
          }

          case BlockType.MOMENT_CARD: {
            return <MomentsCardsListWidget citySlug={currentCity.slug} listId={contentful_id} />
          }
          case BlockType.FAVORITES: {
            return isAuth ? <FavoriteProducts key={name} city={currentCity} listId={block.contentful_id} /> : null
          }

          case BlockType.RIBBON_MESSAGES: {
            return (
              <React.Fragment>
                <RibbonMessageSliderWidget citySlug={currentCity.slug} listId={block.contentful_id} />
              </React.Fragment>
            )
          }

          default:
            return null
        }
      })}
      {!isAuth && <OrderTrackingCTA citySlug={currentCity.slug} />}
    </HomeWrapper>
  )
}

export default Home
