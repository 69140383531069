import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import Swiper from 'react-id-swiper'
import styled from 'styled-components'
import { useResolution, breakpoints } from '@ecommerce/shared'
import { SwiperParams } from '../../types/Constants'
import BannerSlider from './BannerSlider'

const SliderSkeleton = loadable(() => import('../Skeletons/SliderSkeleton'))

/* TODO: NEW IMAGE RATIOS TO BE IMPLEMENTED BY UI TEAM.
const imageRatio = {
  desktop: 1920 / 500,
  tablet: 768 / 368,
  mobile: 367 / 250,
}
*/

const imageRatio = {
  desktop: 1440 / 388,
  tablet: 768 / 368,
  mobile: 784 / 521,
}

const calcMinHeight = (device: keyof typeof imageRatio) => `${100 / imageRatio[device]}vw`

const Wrapper = styled.div<{ IconRight: string; IconLeft: string }>`
  height: fit-content;
  max-width: 100vw;
  min-width: 100vw;
  min-height: ${calcMinHeight('mobile')};
  background: ${({ theme }) => theme.colors.darkLow};
  img {
    height: auto;
  }
  /* Swiper class style */
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 27px;
  }
  .swiper-pagination-bullet {
    width: 14.8px;
    height: 14.8px;
    background: ${(props) => props.theme.colors.bodyText};
    &-active,
    &-clickable {
      background: ${(props) => props.theme.colors.primary};
      outline: none;
      user-select: none;
    }
    &-active {
      opacity: 0.75;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: 375px;
  }
  @media (${breakpoints.desktop.min}) {
    min-height: 504px;
    background: ${({ theme }) => theme.colors.bodyBackground.alt};
  }
  .swiper-button {
    display: none;
    &-next,
    &-prev {
      background-size: 24px 24px;
      background-position: center;
      display: none;
      ::after {
        content: none;
      }
    }
    @media (${breakpoints.desktop.min}) {
      &-next {
        right: 23px;
        background-image: url(${(props) => (props.IconRight ? props.IconRight : '')});
      }
      &-prev {
        left: 23px;
        background-image: url(${(props) => (props.IconLeft ? props.IconLeft : '')});
      }
      &-next,
      &-prev {
        display: block;
        background-repeat: no-repeat;
        color: ${(props) => props.theme.colors.primary};
        width: 40px;
        height: 40px;
        opacity: 0.5;
        border-radius: 80px;
        background-color: ${(props) => props.theme.colors.dark};
        user-select: none;
        outline: none;
        border: none;
        transition: all 0.5s;
        outline-style: none;
        -moz-outline-style: none;
        :hover {
          background-color: ${(props) => props.theme.colors.primary};
          opacity: 0.8;
        }
      }
    }
  }
`
interface ImagesItem {
  background: string
  productImage: string
}

interface Props {
  isLoading?: boolean
  items: {
    imagesMobile: ImagesItem[]
    imagesDesktop: ImagesItem[]
    imagesTablet: ImagesItem[]
    slugs: string[]
    names: string[]
    titles: string[]
    preTitles: string[]
    descriptions: string[]
    actionLabels: string[]
    legals: string[]
  }
  onSlideClick?: (slug?: string) => void
}

const Slider = (props: Props) => {
  const {
    imagesMobile,
    imagesDesktop,
    imagesTablet,
    slugs,
    names,
    titles,
    preTitles,
    descriptions,
    actionLabels,
    legals,
  } = props.items

  const params: SwiperParams = {
    initialSlide: 0,
    rebuildOnUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    speed: 1000,
  }
  const resolution = useResolution()

  const [imgArray, setImgArray] = useState<typeof imagesMobile>([])

  useEffect(() => {
    if (resolution?.isMobile) {
      setImgArray(imagesMobile)
    }
    if (resolution?.isTablet) {
      setImgArray(imagesTablet)
    }
    if (resolution?.isDesktop) {
      setImgArray(imagesDesktop)
    }
  }, [resolution])

  function handlerShowMore(position: number) {
    if (!props.onSlideClick) return null

    return props.onSlideClick(slugs[position])
  }

  if (props.isLoading)
    return (
      <Wrapper
        IconRight="https://images.ctfassets.net/16npdkkoi5mj/31JdIGZYomjqVIatKBfQjb/2d46786c7dc837816bf76ec1a642eb39/chevron_right.png"
        IconLeft="https://images.ctfassets.net/16npdkkoi5mj/4PRKLyEQRdHKKUQmAqZQWZ/56eda383827ab0d6f4b656ae3a7e109a/chevron_left.png"
      >
        <div>
          <Swiper {...params}>
            <SliderSkeleton />
          </Swiper>
        </div>
      </Wrapper>
    )

  return (
    <Wrapper
      IconRight="https://images.ctfassets.net/16npdkkoi5mj/31JdIGZYomjqVIatKBfQjb/2d46786c7dc837816bf76ec1a642eb39/chevron_right.png"
      IconLeft="https://images.ctfassets.net/16npdkkoi5mj/4PRKLyEQRdHKKUQmAqZQWZ/56eda383827ab0d6f4b656ae3a7e109a/chevron_left.png"
      data-test="banners-slider"
    >
      <Swiper {...params}>
        {imgArray.map((image, i) => {
          return (
            <div key={`slide-${i}`}>
              <BannerSlider
                productImage={image?.productImage}
                title={titles[i]}
                preTitle={preTitles[i]}
                description={descriptions[i]}
                actionLabel={actionLabels[i]}
                legal={legals[i]}
                image={image}
                onClick={() => handlerShowMore(i)}
                clickable={!!props.onSlideClick && !!slugs[i] && slugs[i] !== '' && !actionLabels[i]}
              />
            </div>
          )
        })}
      </Swiper>
    </Wrapper>
  )
}
export default Slider
