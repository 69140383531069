import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { toCssPrefix, Button, Image, breakpoints } from '@ecommerce/shared'

type Props = {
  citySlug: string
}

const iconUrl = '//images.ctfassets.net/5czn5snkqxg9/6uCmx57OqLbs3WLbirpufG/904ee13daab80bd7fb52f0e25cf30cfa/truck.png'
const { cssPrefix, toPrefix } = toCssPrefix('OrderTrackingCTA')

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.bodyBackground.alt};
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  .${cssPrefix} {
    &inner {
      text-align: center;
      background: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      padding: 0 25px 14px;
      width: 334px;

      img {
        width: 80px;
        height: 80px;
        margin-top: -40px;
      }

      h2 {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
      }

      p {
        font-size: 17px;
        margin: 5px 0 0;
      }

      button {
        margin: 16px 0;
        width: 170px;
      }

      span {
        display: block;
        font-size: 15px;
        line-height: 20px;

        a {
          text-decoration: none;
          color: ${({ theme }) => theme.colors.primary};
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &inner {
        width: 535px;
        margin-top: 40px;
        min-height: 225px;

        h2 {
          font-size: 24px;
        }

        p {
          font-size: 18px;
        }

        button {
          margin: 30px 0;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &inner {
        width: 820px;

        img {
          width: 100px;
          height: 100px;
          margin-top: -50px;
        }

        h2 {
          font-size: 34px;
          margin-top: 10px;
        }

        p {
          margin-top: 15px;
        }
      }
    }
  }
`

const OrderTrackingCTA = ({ citySlug }: Props) => {
  return (
    <Wrapper className={cssPrefix}>
      <div className={toPrefix('inner')}>
        <Image src={iconUrl} alt="delivery-icon" />
        <h2>Haz un seguimiento en línea</h2>
        <p>Para ver el estado de tu pedido</p>
        <Button onClick={() => navigate(`/${citySlug}/tracking`)}>Continuar</Button>
        <span>
          Si ya tienes cuenta entra a Mis Pedidos.&nbsp;
          <Link to={`/${citySlug}/login`}>Iniciar sesión</Link>
        </span>
      </div>
    </Wrapper>
  )
}

export default OrderTrackingCTA
