import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { breakpoints, Button } from '@ecommerce/shared'

const Container = styled(motion.div)<{ clickable?: boolean; background: string }>`
  min-height: 250px;
  width: 100%;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: top center;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: 368px;
  }

  @media (${breakpoints.desktop.min}) {
    min-height: 504px;
  }

  .container {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;

    @media (${breakpoints.desktop.min}) {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .container > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .info-banner {
    display: flex;
  }

  .info-banner > div {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    @media (${breakpoints.tabletPortrait.min}) {
      padding: 1.5rem;
    }
    @media (${breakpoints.desktop.min}) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .info-banner h2,
  .info-banner h3 {
    font-size: 32px;
    margin: 0px;
    @media (${breakpoints.tabletPortrait.min}) {
      font-size: 40px;
    }
    @media (${breakpoints.desktop.min}) {
      font-size: 80px;
    }
  }

  .info-banner h3 {
    font-size: 20px;
    @media (${breakpoints.tabletPortrait.min}) {
      font-size: 33px;
    }
    @media (${breakpoints.desktop.min}) {
      font-size: 56px;
      line-height: 1;
    }
  }

  .info-banner p {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 2px;
    color: ${(props) => props.theme.colors.black};
    line-height: 1.4;
    font-weight: bold;
    @media (${breakpoints.tabletPortrait.min}) {
      font-size: 16px;
    }
    @media (${breakpoints.desktop.min}) {
      font-size: 32px;
    }
  }

  .info-banner span {
    font-size: 11px;
    display: block;
    margin-bottom: 15px;
    font-weight: lighter;
    color: ${(props) => props.theme.colors.black};
    margin-top: 15px;
    @media (${breakpoints.tabletPortrait.min}) {
      font-size: 16px;
    }
    @media (${breakpoints.desktop.min}) {
      font-size: 24px;
    }
  }

  .image {
    display: flex;
  }

  .image > div {
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .banner-button {
    min-width: 96px;
  }

  .image-placeholder {
    min-height: 250px;
    @media (${breakpoints.tabletPortrait.min}) {
      min-height: 250px;
    }
    @media (${breakpoints.desktop.medium}) {
      min-height: 500px;
    }
  }

  .imagen-responsive {
    max-width: 100% !important;
  }
`
interface Banner {
  background: string
  promotion?: string
  productImage?: string
}
interface Props {
  title: string
  preTitle?: string
  description: string
  actionLabel?: string
  legal: string
  productImage: string
  image: Banner
  onClick?: (e?: React.MouseEvent) => void
  clickable?: boolean
}
const BannerSlider = (props: Props) => {
  const { title, preTitle, actionLabel, description, legal } = props
  const { background, productImage } = props.image

  return (
    <Container
      key={background}
      onClick={(e) => (props.onClick && !actionLabel ? props.onClick(e) : null)}
      clickable={props.clickable}
      background={background}
    >
      <div className="container">
        <div>
          <div className="info-banner">
            <div>
              {preTitle ? <h2>{preTitle}</h2> : null}
              {title ? <h3>{title}</h3> : null}
              {description ? <p>{description}</p> : null}
              {actionLabel ? (
                <Button className="banner-button" onClick={(e) => (props.onClick ? props.onClick(e) : null)}>
                  {actionLabel}
                </Button>
              ) : null}
              {legal ? <span>{legal}</span> : null}
            </div>
          </div>
          <div className="image">
            <div>
              {productImage ? (
                <img src={productImage} alt="promo" className="imagen-responsive" />
              ) : (
                <div className="image-placeholder" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BannerSlider
