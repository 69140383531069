import { Slide } from '../types/PgPages'

export interface SliderHomeQueryResult {
  pgPageCollection: {
    items: {
      template: {
        slider: {
          slidesCollection: {
            items: Slide[]
          }
        }
      }
    }[]
  }
}

export default `
  query SliderHome($currentCitySlug: String!) {
    pgPageCollection(where: {
      pageType: "home",
        OR: [
          {
            sublocations_contains_some: [$currentCitySlug]
          },
          {
            location: {
              slug: $currentCitySlug
            }
          }
        ],
    },
      limit: 1
  ) {
      items {
        template {
          ... on TmHome {
            slider {
              slidesCollection {
                items {
                  name
                  title
                  preTitle
                  description
                  legal
                  actionLabel
                  actionSlug
                  actionSlugLong
                  desktopImage {
                    url
                  }
                  desktopProductImage {
                    url
                  }
                  mobileImage {
                    url
                  }
                  mobileProductImage {
                    url
                  }
                  tabletImage {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

    subLocationPage: pgPageCollection(where: { location: { slug: $currentCitySlug }, pageType: "home" }, limit: 1) {
      items {
        template {
          ... on TmHome {
            slider {
              slidesCollection {
                items {
                  name
                  title
                  preTitle
                  description
                  legal
                  actionLabel
                  actionSlug
                  actionSlugLong
                  desktopImage {
                    url
                  }
                  desktopProductImage {
                    url
                  }
                  mobileImage {
                    url
                  }
                  mobileProductImage {
                    url
                  }
                  tabletImage {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
