import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { useTabs } from '../hooks/useTabs'
import Layout from '../components/Layout'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'
import HomeTemplate from '../templates/home'
import { contentfulThemeToThemeExtension } from '../graphql/global/theme'

const Home = (props: PgPageProps) => {
  const { currentCity, productCategories } = props.pageContext

  const getPage = () => {
    if (props.data.locationPage && props.data.locationPage.edges.length > 0)
      return props.data.locationPage.edges[0].node
    if (props.data.subLocationPage && props.data.subLocationPage.edges.length > 0)
      return props.data.subLocationPage.edges[0].node
    return props.data.allContentfulPgPage.edges[0].node
  }

  const {
    title: titlePage,
    blocks: pageBlocks,
    template: { blocks: templateBlocks },
    theme: pageTheme,
  } = getPage()

  const blocks = pageBlocks && pageBlocks.length ? pageBlocks : templateBlocks

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: any) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })

  const { tabs } = useTabs()

  return (
    <ThemeProvider theme={theme}>
      <Layout
        id="layout"
        title={titlePage}
        currentCity={currentCity}
        pathName={`/${currentCity.slug}`}
        location={props.location}
        categories={productCategories}
      >
        <HomeTemplate pageBlocks={blocks} tabs={tabs} currentCity={currentCity} />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(Home)

export const query = graphql`
  query HomeQuery($homeId: String!, $citySlug: String!) {
    locationPage: allContentfulPgPage(filter: { location: { slug: { eq: $citySlug } }, pageType: { eq: "home" } }) {
      edges {
        node {
          title
          theme {
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandActive
            brandDefault
            brandHover
            brandSelected
            brandTextActive
            brandTextDefault
            brandTextHover
            brandTextSelected
          }
          blocks {
            name
            contentful_id
            blockType
          }
          template {
            ... on ContentfulTmHome {
              blocks {
                name
                contentful_id
                blockType
              }
            }
          }
        }
      }
    }

    subLocationPage: allContentfulPgPage(filter: { sublocations: { in: [$citySlug] }, pageType: { eq: "home" } }) {
      edges {
        node {
          title
          theme {
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandActive
            brandDefault
            brandHover
            brandSelected
            brandTextActive
            brandTextDefault
            brandTextHover
            brandTextSelected
          }
          blocks {
            name
            contentful_id
            blockType
          }
          template {
            ... on ContentfulTmHome {
              blocks {
                name
                contentful_id
                blockType
              }
            }
          }
        }
      }
    }

    allContentfulPgPage(filter: { contentful_id: { eq: $homeId }, pageType: { eq: "home" } }) {
      edges {
        node {
          title
          theme {
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandActive
            brandDefault
            brandHover
            brandSelected
            brandTextActive
            brandTextDefault
            brandTextHover
            brandTextSelected
          }
          template {
            ... on ContentfulTmHome {
              blocks {
                contentful_id
                blockType
              }
            }
          }
        }
      }
    }
  }
`
