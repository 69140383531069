import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled, { useTheme } from 'styled-components'
import { breakpoints, Button, capitalize, useAuth, getAuth, City, slugify } from '@ecommerce/shared'
import { ProductListSlider } from '../../components/ProductListSlider'
import ProductCart from '../../components/ShoppingCart/ProductCart'
import SliderWrapper from '../../components/SliderWrapper'
import { getProductsBySlugLocation } from '../../utils/algolia'
import useQuery from '../../hooks/useQuery'
import productListQuery, { QueryResult } from '../../graphql/productListQuery'
import { productNavigate } from '../../utils/gtm'

type Props = {
  listId: string
  city: City
}

const Wrapper = styled.div`
  margin: 35px 0;
`

const TrailingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  width: 172px;
  margin: 0 66px 50px 30px;

  h4 {
    margin: 0 0 8px;
    font-weight: 700;
    font-size: 24px;
    display: none;
  }

  button {
    margin: 18px 0;
    width: 155px;
    height: 30px;
    font-size: 13px;
  }

  .cta-text {
    &::first-letter {
      font-size: 0;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    width: 273px;
    margin: 0 36px 25px 0;

    h4 {
      display: block;
    }

    .cta-text {
      text-transform: lowercase;

      &::first-letter {
        font-size: inherit;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin-bottom: 50px;
    margin-left: 30px;
  }
`

const FavoriteProducts = ({ listId, city }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [algoliaProducts, setAlgoliaProducts] = useState<ProductCart[]>([])
  const [renderList, setRenderList] = useState(true)

  const {
    state: { favoriteSkus },
  } = useAuth()
  const theme = useTheme()
  const isAuth = getAuth()

  const { data: queryResult } = useQuery<QueryResult>(productListQuery, { variables: { listId }, deps: [favoriteSkus] })
  const data = queryResult?.orList
  const listTitle = data?.title ?? ''
  const actionSlug = data?.actionSlug

  useEffect(() => {
    if (!favoriteSkus || favoriteSkus.length === 0) return

    const fetchData = async () => {
      try {
        const products = await getProductsBySlugLocation(
          slugify(city.commerceLayer.stockLocation.name),
          favoriteSkus.map(String),
        )

        setAlgoliaProducts(
          products?.map((product) => ({
            ...product,
            onNavigate: () => productNavigate({ product, listName: 'Favoritos Cliente', slugLocation: city.slug }),
          })) ?? [],
        )
      } catch (err) {
        setRenderList(false)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [favoriteSkus])

  const onShowMoreClick = () => navigate(`/${city.slug}/mis-favoritos`)

  if (!favoriteSkus || favoriteSkus.length < 2 || !renderList || !isAuth) return null

  const trailingComponent = (
    <TrailingWrapper>
      <div>
        <h4>Selecciona productos</h4>
        <p className="cta-text">{`Y ${capitalize('mantente al día con las promociones de tus favoritos.', true)}`}</p>
        {actionSlug && (
          <Button onClick={() => navigate(`/${city.slug}/${actionSlug}`)} btnColor="black">
            Ver catálogo
          </Button>
        )}
      </div>
    </TrailingWrapper>
  )

  return (
    <Wrapper>
      <SliderWrapper
        bgBottom={theme?.colors?.gray}
        paddingBottom={data?.paddingBottom}
        paddingTop={data?.paddingTop}
        onShowMoreClick={onShowMoreClick}
        showMoreText="Editar"
        isLoading={isLoading}
        title={listTitle}
      >
        <ProductListSlider
          products={algoliaProducts}
          isLoading={isLoading}
          trailingComponent={!isLoading && favoriteSkus?.length <= 3 ? trailingComponent : null}
        />
      </SliderWrapper>
    </Wrapper>
  )
}

export default FavoriteProducts
